import Miso from "./ts/global";
//global.Miso = Miso;

//React INIT
import React from 'react';
import * as ReactDOM from 'react-dom';
global.React = React;
global.ReactDOM = ReactDOM;

// Main SCSS
require("./scss/main.scss");

// Integrate CSS
require("./css/integrate.css");

// Load Toastr SCSS
//require("toastr/build/toastr.min.css");
// TO DO do we need these?
//require("./img/brown.png");
require("./img/logo.png");
//require("./img/MISOHeaderIcon.png");
require("./img/MISOIcon.png");
//require("./img/tower.png");
//require("./img/turbines.png");
//import * as Components from "./ts/components";